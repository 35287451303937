@purple5: #40386a;
@purple4: #564c90;
@purple3: #6b5db1;
@purple2: #a798fe;
@purple1: #bfbaff;

@blue6: #33a4dc;
@blue5: #1b85c2;
@blue4: #14a7bd;
@blue3: #1bacbf;
@blue2: #86eafe;
@blue1: #c6f5f9;

@green5: #005201;
@green4: #009504;
@green3: #00b007;
@green2: #00DCCD;
@green1: #c3fb92;

@yellow5: #997d27;
@yellow4: #cea732;
@yellow3: #fecf3e;
@yellow2: #ffd86a;
@yellow1: #f9de94;

@red6: #E37495;
@red5: #c83835;
@red4: #f44478;
@red3: #ff5648;
@red3bis: rgba(255,86,72,0.3);
@red2: #e954a1;
@red1: #ff96ce;

@gray5: #232B31;
@gray4: #313B44;
@gray3: #3B4752;
@gray2: #626C75;
@gray1: #D8DADC;

@orange1: #ed7323;
@orange2: #f7a53a;

@monogray6: #1a1a1a;
@monogray5: #2c2c2c;
@monogray4: #626262;
@monogray3: #c3c3c3;
@monogray2: #f0f0f0;
@monogray1: #f6f6f6;


@other-gray3: #191B1F;
@other-gray2: #202328;
@other-gray1: #646972;








@nbCols: 			12;
@gutter: 			20px;


@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@font-size-base:          14px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px
@font-size-smallsmall:         10px;

@font-default-color:        @gray2;
@font-default-lineheight: 	18px;

@standard-padding: 15px;
@standard-margin: 15px;
@little-padding: 5px;
@big-padding: 30px;


@border-color: @gray4;

@toolbar-height: 45px;
@toolbar2-height: 52px;
@height-toolbars: ceil((@toolbar-height + @toolbar2-height));

@cell-height: 34px;




@sidebar-width: 250px;
@maintoolsbar-height: 50px;


@max-width: none;
