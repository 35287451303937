@import url("variables.less");

body {
	// background-color: @gray1;
}

h1 {
	color: @green3;
}

h1.notcolored {
	color: @gray2;
}

h2 {
	font-size: 20px;
	font-weight: 400;
	line-height: 20px;
	color: @gray2;
	margin: 0 0 8px 0;
}

h3,
.M_TabButton {
	font-size: 12px;
	line-height: 13px;
	font-weight: bold;
	text-transform: uppercase;
	color: @gray2;
}

h3 {
	margin: 0 0 8px 0;
}

h6 {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 100;
	font-size: 20px;
}

hr {
	margin: 15px 0;
}

#preferences_table {
	width: 600px;
	margin: 0 auto;
	border-top: 1px solid @gray1;
	padding-top: 30px;

	a {
		text-decoration: none;
	}

	.preferences_pref {
		float: left;
		width: 300px;
		padding-bottom: 30px;
		border-bottom: 1px solid @gray1;
		margin-bottom: 30px;

		img {
			display: block;
			float: left;
			margin-right: 10px;
		}

		h2 {
			float: left;
			width: 255px;
			padding-top: 6px;
		}
	}
}

b {
	font-weight: bold;
	// color: @blue4;
}

strong {
	font-weight: bold;
	color: @blue4;
}

.M_Panel {
	border-top: 5px solid @green3;
	padding: @standard-padding;
}

.M_part {
	// background-color: @gray1;
	padding: @gutter;
}

.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#mainContent {
	width: 100%;
	height: 100%;
}

#mainnav {
	position: absolute;
	top: @maintoolsbar-height;
	left: 0;
	display: none;
	z-index: 1000;
	min-width: @sidebar-width;
	height: calc(100% - @maintoolsbar-height);
	// border-right: 1px solid #b0bec5;
	background-color: @gray5;
	box-sizing: border-box;
	padding: 0;

	li {
		padding: 10px 15px;
	}

	h1 {
		background-color: @gray5;
		height: @maintoolsbar-height;
		font-size: 20px;
		color: white;
		padding: 10px 0 0 20px;
	}

	p {
		// padding: 12px 0 0 5px;
		padding: 2px 0 0 0;
		float: left;
		margin: 0;
		display: block;
	}

	span {
		font-size: 20px;
		display: block;
		float: left;
		margin-right: 10px;
	}
}

// #mainnav span {
// 	width: 34px;
// 	height: 34px;
// 	display: block;
// 	float: left;
// 	background-repeat: no-repeat;
// 	margin: 5px 0 5px 10px;
// }
// #mainnav .menumodule_Home span {
// 	background-image: url(/images/ico_1home-NB.png);
// }
// #mainnav .menumodule_Home:hover span,
// #mainnav .menumodule_Home.over span {
// 	background-image: url(/images/ico_1home.png);
// }
// #mainnav .menumodule_Contacts span {
// 	background-image: url(/images/ico_2contacts-NB.png);
// }
// #mainnav .menumodule_Contacts:hover span,
// #mainnav .menumodule_Contacts.over span {
// 	background-image: url(/images/ico_2contacts.png);
// }
// #mainnav .menumodule_Vacation span {
// 	background-image: url(/images/ico_3abscences-NB.png);
// }
// #mainnav .menumodule_Vacation:hover span,
// #mainnav .menumodule_Vacation.over span {
// 	background-image: url(/images/ico_3abscences.png);
// }
// #mainnav .menumodule_VacationAdmin span {
// 	background-image: url(/images/ico_4abscences-admin-NB.png);
// }
// #mainnav .menumodule_VacationAdmin:hover span,
// #mainnav .menumodule_VacationAdmin.over span {
// 	background-image: url(/images/ico_4abscences-admin.png);
// }
// #mainnav .menumodule_Reports span {
// 	background-image: url(/images/ico_5rapporthebdo-NB.png);
// }
// #mainnav .menumodule_Reports:hover span,
// #mainnav .menumodule_Reports.over span {
// 	background-image: url(/images/ico_5rapporthebdo.png);
// }
// #mainnav .menumodule_ReportsAdmin span {
// 	background-image: url(/images/ico_6rapporthebdo-admin-NB.png);
// }
// #mainnav .menumodule_ReportsAdmin:hover span,
// #mainnav .menumodule_ReportsAdmin.over span {
// 	background-image: url(/images/ico_6rapporthebdo-admin.png);
// }
// #mainnav .menumodule_MonthlyReports span {
// 	background-image: url(/images/ico_6rapportmensuel-NB.png);
// }
// #mainnav .menumodule_MonthlyReports:hover span,
// #mainnav .menumodule_MonthlyReports.over span {
// 	background-image: url(/images/ico_6rapportmensuel.png);
// }
// #mainnav .menumodule_MonthlyReportsAdmin span {
// 	background-image: url(/images/ico_7rapportmensuel-admin-NB.png);
// }
// #mainnav .menumodule_MonthlyReportsAdmin:hover span,
// #mainnav .menumodule_MonthlyReportsAdmin.over span {
// 	background-image: url(/images/ico_7rapportmensuel-admin.png);
// }
// #mainnav .menumodule_Candidates span {
// 	background-image: url(/images/ico_8moduleDRH-NB.png);
// }
// #mainnav .menumodule_Candidates:hover span,
// #mainnav .menumodule_Candidates.over span {
// 	background-image: url(/images/ico_8moduleDRH.png);
// }
// #mainnav .menumodule_Preferences span {
// 	background-image: url(/images/ico_9preferences-NB.png);
// }
// #mainnav .menumodule_Preferences:hover span,
// #mainnav .menumodule_Preferences.over span {
// 	background-image: url(/images/ico_9preferences.png);
// }

#mainnav .nav.nav-main li {
	color: white;
	margin: 0;
	border-bottom: 1px solid @gray2;
	-webkit-transition: background-color 0.5s ease 0s;
	transition: background-color 0.5s ease 0s;
}

#mainnav .nav.nav-main li:hover,
#mainnav .nav.nav-main li.over {
	color: @gray1;
}

#mainnav .nav.nav-main li:hover {
	background-color: @gray2;
}

#mainnav .nav.nav-main a {
	text-decoration: none;
}

#mainnav .nav.nav-main > a.active > li {
	color: white;
}

#mainnav hr {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#maincenter {
	height: 100%;
	width: 100%;
	background-color: @monogray2;
}

#maintoolsbar {
	height: @maintoolsbar-height;
	background-color: @blue6;
	color: white;
}

#maintoolsbarcontent {
	width: 100%;
	// max-width: @max-width;
	// margin: 0 auto;
}

#maintoolsbar img {
	width: 35px;
	height: 35px;
	border-radius: 50%;
}

#main {
	height: calc(100% - @maintoolsbar-height);
	position: relative;
	overflow: hidden;
	width: 100%;
	max-width: @max-width;
	margin: 0 auto;
}

.mainlogo {
	// background-image: url(/images/logo02.png?v=1);
	top: 100px;
	width: 138px;
	height: 40px;
	font-size: 300%;
	float: left;
	cursor: pointer;
}

.mainlogo p {
	margin: 15px 0 0 0;
}

.mainlogo img {
	margin: 5px 0 0 0;
}

.mainseperation {
	border-left: 1px solid @gray1;
	width: 1px;
	float: left;
	height: 25px;
	margin: 13px 20px 0 20px;
}

.mainsearch {
	float: left;
	font-size: 20px;
	margin: 16px 0 0 0;
}

#mainsearchinput {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 100;
	font-size: 18px;
	border: 0;
	padding: 0;
	background-color: transparent;
	color: white;

	&::-webkit-input-placeholder {
		color: white;
	}

	&::-moz-placeholder {
		color: white;
	}

	&:-ms-input-placeholder {
		color: white;
	}
}

.maintools {
	float: left;
	font-size: 20px;
	margin: 7px 0 0 20px;
}

#contacts_simplesearch input {
	font-weight: 100;
}

#contacts_bases input {
	font-weight: 100;
}

#formations_search input {
	font-weight: 100;
}

.fa.big {
	font-size: 30px;
}

.home_icons {
	text-align: center;
	margin: 0 -1%;

	.fa {
		font-size: 50px;
	}

	.M_Badge {
		right: 30%;
	}
}

.home_bgwhite {
	background-color: white;
	padding: 15px;
	margin: 0 1%;
	min-height: 210px;
}

.home_littlepart {
	float: left;
	position: relative;
	width: 12.28%;
	margin: 1%;
	background-color: white;
	padding: 15px;

	h1 {
		font-size: 20px;
		line-height: 22px;
		color: @gray2;
	}
}

.home_bgwhite_height {
	background-color: white;
	padding: 15px;
	margin: 0 1%;
}

.noUi-connect.bg_colOrange1 {
	background-color: @yellow2;
}

.noUi-connect.bg_colBlue1 {
	background-color: @blue2;
}

.noUi-connect.bg_colGreen1 {
	background-color: @green2;
}

.noUi-connect.bg_colRed1 {
	background-color: @red2;
}

@keyframes bganim {
	from {
		background-position: 0;
	}
	to {
		background-position: 42px;
	}
}

.noUi-target.noUi-connect {
	background-image: url(/images/fond-hachure-ok.png);
	animation-duration: 1s;
	animation-name: bganim;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.vacation_wait {
	background-image: url(/images/fond-hachure-ok.png);
	animation-duration: 2s;
	animation-name: bganim;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

#vacation_gain {
	padding: 8px 30px 0 0;
}

#vacation_cal1title,
#vacation_cal2title {
	text-align: right;
}

#vacationwinedit_va_start2 .M_LabelRight,
#vacationwinedit_va_end2 .M_LabelRight {
	margin-top: -5px;
	line-height: 14px;
}

#vacation_previousmonth,
#vacation_nextmonth {
	color: @gray1;
	cursor: pointer;
	font-size: 40px;
}

#vacation_previousmonth:hover,
#vacation_nextmonth:hover {
	color: @gray2;
}

#vacationwinedit_va_start2 label,
#vacationwinedit_va_end2 label {
	padding-left: 3px;
}

.faicon {
	background-color: @blue3;
	padding: 5px 7px 0 7px;
	border-radius: 3px;
	height: 24px;
	color: white;
	font-size: 14px;
}

.vacation_status {
	padding: 3px 0;
	border-radius: 3px;
	text-align: center;
	text-transform: uppercase;
	color: white;
	width: 80px;
	float: left;
	margin-right: 10px;
	font-weight: bold;
}

.little {
	font-size: @font-size-smallsmall;
	line-height: @font-size-smallsmall;
	// color: @blue4;
	line-height: 11px;
}

.little2 {
	font-size: @font-size-smallsmall;
	line-height: @font-size-smallsmall;
	color: @gray4;
	line-height: 11px;
}

.inputSearch {
	background-color: transparent;
	border: 0;
	outline: none;
	width: 200px;
	height: 43px;
	padding: 0px 15px;
	font-size: 20px;
	font-weight: 100;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#home_colA1 {
	height: 340px;
}

// #home_actions {
// 	height: 200px;
// }
.home_3stats {
	float: left;
	border-right: 1px solid @border-color;
	// padding: @standard-padding;
}

.home_3stats:last-child {
	border-right: 0;
}

.home_3stats_num {
	font-size: 50px;
	line-height: 50px;
	color: @blue3;
	text-align: center;
	font-weight: 100;
}

.home_3stats_num .fa {
	font-size: 30px;
	display: inline-block;
	vertical-align: top;
	padding-top: 8px;
}

.home_3stats_num_ng {
	font-size: 50px;
	line-height: 50px;
	color: @red3;
	text-align: center;
	font-weight: 100;
}

.home_3stats_num_ng .fa {
	font-size: 30px;
	display: inline-block;
	vertical-align: top;
	padding-top: 8px;
}

.home_3stats_week {
	color: @monogray3;
}

#home_bg {
	background-image: url(/images/bg_home1.jpg);
	background-size: cover;
	background-position: center;
	height: 380px;
}

#home_bg2 {
	width: 100%;
	height: 100%;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,000000+100&amp;0+50,0.8+60,0.8+100 */
	background: -moz-linear-gradient(left,
	rgba(255, 255, 255, 0) 0%,
	rgba(128, 128, 128, 0) 50%,
	rgba(102, 102, 102, 0.8) 60%,
	rgba(0, 0, 0, 0.8) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear,
	left top,
	right top,
	color-stop(0%, rgba(255, 255, 255, 0)),
	color-stop(50%, rgba(128, 128, 128, 0)),
	color-stop(60%, rgba(102, 102, 102, 0.8)),
	color-stop(100%, rgba(0, 0, 0, 0.8))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left,
	rgba(255, 255, 255, 0) 0%,
	rgba(128, 128, 128, 0) 50%,
	rgba(102, 102, 102, 0.8) 60%,
	rgba(0, 0, 0, 0.8) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left,
	rgba(255, 255, 255, 0) 0%,
	rgba(128, 128, 128, 0) 50%,
	rgba(102, 102, 102, 0.8) 60%,
	rgba(0, 0, 0, 0.8) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left,
	rgba(255, 255, 255, 0) 0%,
	rgba(128, 128, 128, 0) 50%,
	rgba(102, 102, 102, 0.8) 60%,
	rgba(0, 0, 0, 0.8) 100%); /* IE10+ */
	background: linear-gradient(to right,
	rgba(255, 255, 255, 0) 0%,
	rgba(128, 128, 128, 0) 50%,
	rgba(102, 102, 102, 0.8) 60%,
	rgba(0, 0, 0, 0.8) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#cc000000',GradientType=1); /* IE6-9 */
}

#home_colB1 {
	height: 120px;
}

#home_colB1 h3 {
	text-align: center;
}

#home_chart1 {
	height: 220px;
}

#home_4stats .M_Col3 {
	h2,
	h3,
	h4,
	h5 {
		color: white;
	}

	.home_4stats_top {
		padding: @standard-padding;

		h2,
		h3 {
			margin: 0;
		}

		h2 {
			margin-bottom: 4px;
		}
	}

	.home_4stats_bottom {
		background-color: white;
		padding: @standard-padding;

		.M_FloatLeft {
			width: 50%;
			text-align: center;

			span {
				display: block;
				color: @gray2;
			}

			span:first-child {
				font-size: 30px;
				line-height: 30px;
			}
		}
	}

	&:first-child .home_4stats_top {
		background-color: @red2;
	}

	&:nth-child(2) .home_4stats_top {
		background-color: @blue3;
	}

	&:nth-child(3) .home_4stats_top {
		background-color: @green3;
	}

	&:last-child .home_4stats_top {
		background-color: @green4;
	}
}

@roomsAlltitleHeight: 50px;
#roomsforms_rooms_col_contacts,
#rooms_col_contacts {
	float: left;
	height: calc(100% - @roomsAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

@roomsAlltitleHeight: 120px;
#roomsforms_rooms_col_keywords {
	float: left;
	height: calc(100% - @roomsAlltitleHeight);
	width: 250px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

@roomsformsAlltitleHeight: 90px;
#roomsforms_rooms_col_contacts {
	float: left;
	height: calc(100% - @roomsformsAlltitleHeight);
	width: 450px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#rooms_col_details {
	float: left;
	height: calc(100% - @roomsAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#roomsforms_rooms_col_details {
	float: left;
	height: calc(100% - @roomsformsAlltitleHeight);
	width: calc(100% - (700px + (@gutter * 2)));
	box-sizing: border-box;
}

#rooms_list_contacts,
#rooms_list_groups,
#rooms_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px - 34px);
}

@toolbarroomsroomsforms: 90px;
#roomsforms_rooms_list_contacts {
	height: calc(100% - @toolbarroomsroomsforms - 34px);
}

@toolbarformsroomsforms: 52px;
#roomsforms_forms_list_contacts,
#roomsforms_elts_list_contacts,
#roomsforms_spcfts_list_contacts,
#roomsforms_keywords_list {
	height: calc(100% - @toolbarformsroomsforms - 34px - 34px - 34px);
}

@messagesAlltitleHeight: 50px;
#messages_col_contacts {
	float: left;
	height: calc(100% - @messagesAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#messages_col_details {
	float: left;
	height: calc(100% - @messagesAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#messages_list_contacts,
#messages_list_groups,
#messages_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

#messages_list_contacts {
	.M_SimpleListItem {
		height: auto;
		padding: 9px 15px;
	}
}

@infosAlltitleHeight: 50px;
#infos_col_contacts {
	float: left;
	height: calc(100% - @infosAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#infos_col_details {
	float: left;
	height: calc(100% - @infosAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#infos_list_contacts,
#infos_list_groups,
#infos_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

#infos_list_contacts {
	.M_SimpleListItem {
		height: auto;
		padding: 9px 15px;
	}

	.M_SimpleListItem.alreadyreaded {
		background-color: rgba(233, 84, 161, 0.37);
	}
}

@investigationsAlltitleHeight: 50px;
#investigations_col_contacts {
	float: left;
	height: calc(100% - @investigationsAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#investigations_col_details {
	float: left;
	height: calc(100% - @investigationsAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#investigations_list_contacts,
#investigations_list_groups,
#investigations_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

#investigations_list_contacts {
	.M_SimpleListItem {
		height: auto;
		padding: 9px 15px;
	}

	.M_SimpleListItem.alreadyreaded {
		background-color: rgba(233, 84, 161, 0.37);
	}
}

@quizAlltitleHeight: 50px;
#quiz_col_questions {
	float: left;
	height: calc(100% - @quizAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#quiz_col_details {
	float: left;
	height: calc(100% - @quizAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#quiz_list_questions,
#quiz_list_groups,
#quiz_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

#quiz_list_questions {
	.M_SimpleListItem {
		height: auto;
		padding: 9px 15px;
	}

	.M_SimpleListItem.alreadyreaded {
		background-color: rgba(233, 84, 161, 0.37);
	}
}

@establishmentsAlltitleHeight: 50px;
#establishments_col_contacts {
	float: left;
	height: calc(100% - @establishmentsAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#establishments_col_details {
	float: left;
	height: calc(100% - @establishmentsAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#establishments_list_contacts,
#establishments_list_groups,
#establishments_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

#establishments_list_contacts {
	.M_SimpleListItem {
		height: auto;
		padding: 9px 15px;
	}
}

@formsAlltitleHeight: 50px;
#forms_col_contacts {
	float: left;
	height: calc(100% - @formsAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#roomsforms_forms_col_contacts,
#roomsforms_elts_col_contacts,
#roomsforms_spcfts_col_contacts,
#roomsforms_keywords_col{
	float: left;
	height: calc(100% - @roomsformsAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#forms_col_details {
	float: left;
	height: calc(100% - @formsAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#roomsforms_spcfts_col_details,
#roomsforms_elts_col_details,
#roomsforms_forms_col_details,
#roomsforms_keywords_col_details{
	float: left;
	height: calc(100% - @roomsformsAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#forms_list_contacts,
#forms_list_groups,
#forms_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

#formsdetails_sections {
	display: flex;
	flex-direction: column;
}

#listItems {
	display: flex;
}

.draggable {
	margin-right: 25px;
	text-align: center;
	line-height: 25px;
	cursor: move;
	margin-bottom: 10px;
}

.draggSection {
	margin: 0 5px 0 15px;
	cursor: move;
}

.draggSectionWorksheet {
	margin: 0 5px 0 15px;
	cursor: move;
}

@documentsAlltitleHeight: 50px;
#documents_alltitles {
	// height: @documentsAlltitleHeight;
	overflow: hidden;
}

#documents_listcontainer {
	height: calc(100% - @documentsAlltitleHeight);
	background-color: white;
}

@planningAlltitleHeight: 140px;
#planning_container {
	height: calc(100% - @planningAlltitleHeight);
	background-color: white;
}

@contactsAlltitleHeight: 50px;
#contacts_alltitles {
	// height: @contactsAlltitleHeight;
	overflow: hidden;
}

#contacts_col_groups {
	float: left;
	height: calc(100% - @contactsAlltitleHeight);
	width: 250px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#contacts_col_contacts {
	float: left;
	height: calc(100% - @contactsAlltitleHeight);
	width: 250px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#contacts_col_details {
	float: left;
	height: calc(100% - @contactsAlltitleHeight);
	width: calc(100% - (250px + 250px + @gutter + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#formations_smallbox {
	float: left;
	height: calc(100% - @contactsAlltitleHeight);
	width: 400px;
	background-color: white;
	margin-right: @gutter;
}

#formations_bigbox {
	float: left;
	height: calc(100% - @contactsAlltitleHeight);
	width: calc(100% - (400px + @gutter + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#contacts_list_groups {
	// height: calc(100% - 75px);
	// padding: 0 0px;
}

#contacts_list_contacts {
	// height: calc(100% - 75px);
	// padding: 0 0px;
}

// #contactedit_main {
//   height: calc(100% - @toolbar-height);
// }

@maintenancerequestAllTitlesHeight: 50px;
#maintenancerequest_col_requests {
	float: left;
	height: calc(100% - @maintenancerequestAllTitlesHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#maintenancerequest_col_details {
	float: left;
	height: calc(100% - @maintenancerequestAllTitlesHeight);
	width: calc(100% - (350px + 250px + @gutter + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#maintenancerequest_col_contacts {
	float: left;
	height: calc(100% - @maintenancerequestAllTitlesHeight);
	width: 250px;
	background-color: white;
	margin-left: @gutter;
}

#maintenancerequest_list_requests,
#maintenancerequest_list_groups,
#maintenancerequest_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

#maintenancerequest_list_requests {
	.M_SimpleListItem {
		height: auto;
		padding: 9px 15px;
	}

	.M_SimpleListItem.alreadyreaded {
		background-color: rgba(233, 84, 161, 0.37);
	}
}

@roomspublicsAllTitlesHeight: 50px;
#roomspublics_col_contacts {
	float: left;
	height: calc(100% - @messagesAlltitleHeight);
	width: 350px;
	background-color: white;
	margin-right: @gutter;
	// border-right: 1px solid #b0bec5;
	// box-sizing: border-box;
}

#roomspublics_details {
	float: left;
	height: calc(100% - @messagesAlltitleHeight);
	width: calc(100% - (350px + @gutter));
	box-sizing: border-box;
	background-color: white;
}

#roomspublics_list_contacts {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

#roomspublics_list_contacts {
	.M_SimpleListItem {
		height: auto;
		padding: 9px 15px;
	}
}

#roompublicdetail_nbdays input {
	width: auto;
}

.roompublicdetail_public {
	background-color: #00b007;
	color: white;
}


.M_Window {
	background-color: @monogray2;
}

.M_Window h2 {
	color: @blue4;
	// font-weight: bold;
	// font-style: italic;
}

.M_FormCaret {
	background-color: @blue3;
	color: white;
}

.M_FormButton {
	background-color: @blue3;
	color: white;
}

.M_FormButton.disabled {
	background: #999;
	color: #555;
	cursor: not-allowed;
  }

@arrow-size: 8px;
.messageContent {
	float: left;
	width: calc(100% - 35px - @arrow-size);
	padding: 10px;
	background-color: @gray1;
	position: relative;
}

.messageContent.right {
	margin: 0 @arrow-size 10px 0;
}

.messageContent.left {
	margin: 0 0 10px @arrow-size;
}

.messageTitle {
	font-size: 11px;
	text-align: center;
}

.messageContent.right .arrow {
	width: 0;
	height: 0;
	border-top: @arrow-size solid transparent;
	border-bottom: @arrow-size solid transparent;
	border-left: @arrow-size solid @gray1;
	position: absolute;
	right: -@arrow-size;
}

.messageContent.left .arrow {
	width: 0;
	height: 0;
	border-top: @arrow-size solid transparent;
	border-bottom: @arrow-size solid transparent;
	border-right: @arrow-size solid @gray1;
	position: absolute;
	left: -@arrow-size;
}

.messageContent .fa-trash {
	cursor: pointer;
}

.winimage_fileslist img {
	display: inline;
	margin: 15px 15px 0 0;
}

.contactsdetail_frame {
	text-align: center;
	line-height: 15px;
}

.contactsdetail_frame .contactsdetail_frame_nb {
	font-size: 30px;
	line-height: 20px;
	color: @blue1;
	padding-top: 10px;
	// font-weight: bold;
}

// .M_Col4.M_MarginRight.contactsdetail_frame {
//   padding: 10px 10px;
// }
.contacts_details_maindiv .M_TableList td {
	// font-size: 13px;
	height: auto;
	padding: 2px 5px;
}

.contacts_details_maindiv h2 {
	margin-top: 27px;
}

.contacts_details_maindiv h1 + h2 {
	margin-top: 0;
}

.contacts_details_maindiv h3,
#roomsforms_rooms_col_details h3,
#roomsforms_forms_col_details h3,
#roomsforms_elts_col_details h3,
#roomsforms_spcfts_col_details h3,
#rooms_col_details h3,
#forms_details h3 {
	color: @blue3;
}

.contacts_details_maindiv .bg_col6 h3 {
	color: @font-default-color;
}

// .M_TabButton {
// 	font-size: 12px;
// 	text-transform: uppercase;
// 	color: @gray2;
// }

#contacts_btsettings,
#contacts_btsearch,
#rooms_btsettings,
#rooms_btsearch,
#roomsforms_rooms_btsettings,
#roomsforms_rooms_btsearch,
#roomsforms_forms_btsettings,
#roomsforms_forms_btsearch,
#roomsforms_keywords_btdelete,
#roomsforms_elts_btdelete,
#roomsforms_elts_btsearch,
#roomsforms_spcfts_btdelete,
#roomsforms_spcfts_btsearch,
#messages_btsettings,
#messages_btsearch,
#forms_btsettings,
#forms_btsearch,
#establishments_btsettings,
#establishments_btsearch {
	margin: 5px 10px 0 0;
	font-size: 15px;
	cursor: pointer;
	color: @gray2;

	&:hover {
		color: @gray1;
	}
}

#contacts_list_contacts,
#contacts_list_groups,
#contacts_list_agencies {
	height: calc(100% - @toolbar2-height - 34px - 34px);
}

.messagelittle {
	font-size: 11px;
	margin-bottom: 10px;
}

#contactswinimport_fields .M_FormGroup {
	margin-bottom: 0;
}

#contactswinimport_fields .M_FormGroup:after {
	content: "";
	display: block;
}

.txt_colwhite {
	color: white;
}

.txt_col1 {
	color: @blue3;
}

.txt_col2 {
	color: @green3;
}

.txt_col3 {
	color: @red3;
}

.txt_col4 {
	color: @purple3;
}

.txt_col5 {
	color: @yellow3;
}

.txt_col6 {
	color: @monogray3;
}

.bg_col1 {
	background-color: @blue1;
}

.bg_col2 {
	background-color: @green3;
}

.bg_col3 {
	background-color: @red3;
	color: white;
}

.m_gantt-task.bg_col3 {
	color: @font-default-color;
}

.bg_col4 {
	background-color: @purple3;
	color: white;
}

.m_gantt-task.bg_col4 {
	color: @font-default-color;
}

.bg_col5 {
	background-color: @yellow3;
}

.bg_col6 {
	background-color: @monogray2;
}

.bg_col7 {
	background-color: @blue3;
	color: white;
}

.m_gantt-task.bg_col7 {
	color: @font-default-color;
}

.bg_col8 {
	background-color: @blue2;
}

@login-width-form: 400px;
#loginpage {
}

#loginpartimage {
	background-image: url(/images/bg_login01.jpg);
	background-size: cover;
	background-position: center;
	width: calc(100% - @login-width-form);
}

#loginpartimage_txt {
	position: absolute;
	bottom: 100px;
	left: 15%;
	width: 70%;

	h1,
	h2 {
		color: white;
		font-weight: normal;
		font-weight: 100;
	}

	h1 {
		font-size: 40px;
		line-height: 42px;
		margin: 0;
		font-style: italic;
	}

	h2 {
		margin: 15px 0 0 0;
	}
}

#loginpartimage_quote {
	position: absolute;
	background-image: url(/images/quote.png);
	width: 66px;
	height: 56px;
	top: 0;
	left: -100px;
}

#loginpartform {
	width: @login-width-form;
	background-color: white;
	padding: 30px;

	img {
		display: inline-block;
	}
}

#loginInfosBt {
	margin: 5px 15px 0 0;
	cursor: pointer;
	display: table;
}

#loginInfosBt > span {
	display: table-cell;
	vertical-align: middle;
	padding: 0 15px 0 0;
	line-height: 10px;
}

#loginFullScreen {
	margin: 18px 0 0 0;
	cursor: pointer;
	font-size: 18px;
}

#loginAlert {
	margin: 18px 0 0 0;
	cursor: pointer;
	font-size: 18px;
}

.loginAlertLi {
	border-bottom: 1px solid @gray1;

	span {
		float: left;
		font-size: 20px;
		width: 30px;
	}

	& > .loginAlertLiContent {
		display: block;
		float: left;
		margin: 0;
		padding: 0;
		width: calc(100% - 40px);
	}

	& > .loginAlertLiContent2 {
		display: block;
		float: left;
		margin: 0 5px 0 0;
		padding: 0;
		width: 35px;
		height: 35px;
	}

	.loginAlertLiLittle {
		font-size: 10px;
		color: @monogray4;
		line-height: 14px;
	}
}

.c3-circle {
	r: 3px;
	stroke-width: 1px;
	stroke: @gray1;
}

.c3-chart-arc text {
	fill: @gray5;
}

#candidates_dd {
	min-height: 300px;
	width: 100%;
	table-layout: fixed;
}

#candidates_dd td {
	vertical-align: top;
	width: 20%;
	padding: 0 10px;
	border-right: 1px solid @gray1;
}

.candidates_dditem {
	// background-color: @red3;
	padding: 5px 10px;
	margin: 0 0 10px 0;
	cursor: pointer;
	border-radius: 5px;
	font-size: 12px;
	line-height: 15px;
}

.candidates_dditem b {
	font-size: @font-size-base;
}

#monthlyreports_chartagencies .c3-axis-y text {
	fill: #3282c3;
}

#monthlyreports_chartagencies .c3-axis-y2 text {
	fill: #977f21;
}

#monthlyreports_chart1 .c3-axis-y text {
	fill: #3282c3;
}

#monthlyreports_chart2 .c3-axis-y text {
	fill: #977f21;
}

a.agency_selector {
	background-color: transparent;
	color: #c7682a;
	border: 1px solid #c7682a;
	text-decoration: none;
	padding: 3px 5px;
	font-size: 18px;
	border-radius: 5px;
}

a.agency_selector.checked {
	background-color: #c7682a;
	color: white;
	border: 1px solid transparent;
}

.agency_picker,
.agency_allbt {
	float: left;
	background-color: #ffffff;
	padding: 5px;
	margin-right: 5px;
	margin-bottom: 5px;
	cursor: pointer;
}

.agency_allbt {
	background-color: @green2;
}

.agency_picker.checked {
	background-color: @blue3;
}

#mainbtmenus {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: red;
	padding: 8px 0 0 0;
	background-color: @gray2;
	text-align: center;
}

.M_WindowBody {
	padding-bottom: 50px;
}

// .M_Window .M_TableListOdd {
// 	background-color: transparent;
// }
// .M_Window .M_TableListEven {
// 	background-color: white;
// }

#preferences_rightsall td {
	padding: 0 10px;
}

#search_result1 img,
#search_result1 h1 {
	float: left;
	display: block;
	margin-right: 30px;
}

#search_result1 li {
	padding-left: 64px;
}

.agencywithcoin {
	background-image: url(/images/coin-coche.png);
	background-repeat: no-repeat;
}

.agencywithred {
	background-color: @red3;
	color: white;
}

.M_Button.primary {
	background-color: @blue6;
}

.M_Button.primary:hover {
	background-color: @orange2;
}

.M_Button.primary.M_ModalSave {
	background-color: @blue3;
}

.M_Button.primary.M_ModalSave:hover {
	background-color: @blue2;
}

.M_Button.primary.M_ModalSave[disabled],
.M_Button.primary.M_ModalSave:disabled,
.M_Button.primary.M_ModalSave:hover[disabled] {
	background-color: transparent;
}

.M_Button.hide {
	display: none;
}

span.hide {
	display: none !important;
}

.M_Checkbox.hide {
	display: none;
}

.M_Row.hide {
	display: none;
}

.Row.hide {
	display: none !important;
}

.M_Button.green {
	background-color: @green3;
}

.M_Button.green:hover {
	background-color: @green2;
}

.M_Button.red {
	background-color: @red3;
}

.M_Button.red:hover {
	background-color: @red2;
}

.infos {
	font-size: 12px;
	line-height: 13px;
}

.infos_element {
	font-size: 12px;
	line-height: 13px;
	text-align: justify;
//	letter-spacing: 2px;
}

.infos_separator {
	margin-top: 11px;
}

@media only screen and (max-width: 1024px) {
	// .mainsearch {
	// 	display: none;
	// }
}

.traceability-color {
	background-color: #5bc0de;
}

.qualitycontrol-color {
	background-color: #ffd86a;
}

.evaluation-color {
	background-color: #E37495;
}

.traceability-textcolor {
	color: #5bc0de;
}

.qualitycontrol-textcolor {
	color: #ffd86a;
}

.evaluation-textcolor {
	color: #E37495;
}

.m_gantt-task {
	color: black;
	text-align: center;
}

.m_gantt-task.error {
	background-color: @red2;
	color: black;
	text-align: center;
}

.m_gantt-task.green {
	background-color: @red6;
	color: black;
	text-align: center;
}

.m_gantt-task.yellow {
	background-color: @yellow2;
	color: black;
	text-align: center;
}

.m_gantt-task.purple {
	background-color: @purple2;
	color: black;
	text-align: center;
}

.m_gantt-task.borderRed {
	position: absolute !important;
	width: 29px !important;
	border: 2px solid #EB1C15 !important;
	background-color: transparent !important;
	color: black !important;
	text-align: center !important;
}
.m_gantt-task.orange {
	background-color: #FFA500;
	color: black;
	text-align: center;
}

.m_gantt-task.borderRedOpacity {
	position: absolute !important;
	width: 29px !important;
	background-color: #EB1C15  !important;
	color: black !important;
	text-align: center !important;
	opacity: 0.5;
}
.m_gantt-task.transparent {
	background-color: transparent;
	color: black;
	text-align: center;
}
.m_gantt-task.red {
	background-color: #EB1C15;
	color: black;
	text-align: center;
}

.m_gantt-task.checked {
	background-color: #1FA055 !important;
	color: black;
	text-align: center;
}

.m_gantt-task.bleuBorderRed {
	background-color: #5bc0de;
	border: 2px solid #EB1C15;
	color: black;
	text-align: center;
}

.investigationsdetails-ico {
	background-image: url(/images/icon_rouge-1.png);
	background-repeat: no-repeat;
	background-size: contain;
	width: 30px;
	height: 30px;
	float: left;
}

.translucidDropDownItem {
	background-color: transparent;
}

.translucidDropDownItem:hover {
	background-color: rgb(255, 255, 255);
}

.translucidDropDown {
	background-color: rgba(240, 240, 240, 0.5);
}

.huebee__cursor {
	width: 30px;
	height: 30px;
}

.weighting {
	background-color: #555;
	color: white;
	//text-decoration: none;
	padding-right: 15px;
	position: relative;
	display: inline-block;
	border-radius: 2px;
}

.weighting .badge {
	position: absolute;
	top: -8px;
	right: -7px;
	padding: 5px 10px;
	border-radius: 50%;
	//background: red;
	color: white;
}

.m_block_keywords {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100px;
	min-height: 50px;
	max-width: 160px;
	width: fit-content;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	border: 1px solid @gray1;
	padding: 3px 10px 3px 10px;
	text-align: center;
	margin-bottom: 20px;
}

.m_block_rooms, .m_block_contacts, .m_block_forms, .m_block_persons {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	min-height: 50px;
	text-align: center;
	width: 200px;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	border: 1px solid @gray1;
	padding: 3px 10px 3px 10px;
	cursor: pointer;
}

.m_block_evaluation {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	min-height: 50px;
	text-align: center;
	width: 200px;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	border: 1px solid @gray1;
	padding: 3px 10px 3px 10px;
	cursor: pointer;
}

.m_block_rooms.red {
	background-color: rgba(255, 0, 0, 0.5);
	color: @gray2;
}

.m_contacts_deleted {
	border: 1px dashed black;
	opacity: 0.6;
}

.isDoneOrPendingRooms {
	cursor: pointer;
}
.m_block_rooms.green {
	background-color: @green1;
	color: @gray2;
	cursor: pointer;
}

.m_block_table {
	display: inline-grid;
  	grid-template-columns: repeat(auto-fit, 200px);
  	min-width: 50vw;
  	/* decorative properties */
  	grid-gap: 10px;
  	padding: 0;
  	list-style: none;
	width:100%;
}

.m_tag_rooms {
    position: absolute;
    top: 0;
    right: 0;
    background-color: gray;
    color: white;
    padding: 4px 8px;
    text-decoration: none;
    font-size: 10px;
    border-radius: 0 5px 0 5px;
    align-self: flex-start;
  }



  .m_info_rooms {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .m_info_photos {
    position: absolute;
    top: 0;
    left: 0;
  }

  .submodule {
	  border-bottom: 0px ! important;
  }

  .progress-bar {
    display: flex;
	width: 100%;
	height: 30px;
	background-color: #ddd;
  }

  .progress-section {
    display: flex;
    align-items: center;
	justify-content: center;
	min-width: 5%;
	color: black;
    font-size: 14px;
	opacity: 0.22;
	cursor: pointer;
	transition: opacity 0.3s, transform 0.3s, box-shadow 0.3s;
	//position: relative;
    //text-align: center;
  }

  .progress-section.done {
	background-color: #A4C289;
  }

  .progress-section.planning {
	background-color: #FE9885;
  }

  .progress-section.undo {
	background-color: rgba(0, 0, 0, 0.2);
  }

  .progress-section.hide {
	display: none;
  }

  .progress-section.selected {
	opacity: 1 !important;
	color: white;
  }

  .progress-bar:hover .progress-section {
	opacity: 0.3;
  }

  .progress-bar .progress-section:hover {
    font-weight: bold;
	transform: scale(1.01);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .noteditableSection {
	  margin-left: 15px;
  }
.noteditableSection .sectiontitle{
	margin-bottom: 5px;
}
.noteditableSection .sectiontasks{
	margin: 10px 15px 15px 15px;
}

.roomsdetail_status_qt, .roomsdetail_status_traceability, .form_traceability {
    display: flex;
    align-items: center;
	margin-bottom: 5px;
}

.roomsdetail_status_qt div, .roomsdetail_status_traceability div, .form_traceability div {
	width: 20px;
	height: 20px;
	margin-right: 5px;
}

.roomsdetail_status_qt p, .roomsdetail_status_traceability p, .form_traceability p {
	margin: 0;
}

.roomsdetail_realise_traceability, .roomsdetail_realise_qt {
	background-color: #1FA055 !important;
}

.roomsdetail_non_realise, .roomsdetail_non_conforme {
	background-color: #EB1C15 !important;
}

.roomsdetail_venir {
	border: 2px solid #EB1C15 !important;
	background-color: transparent !important;
}
.roomsdetail_a_ameliorer {
	background-color: #FFA500 !important;
}
.roomsforms_keyword_item {
	margin-left: 15px;
}
.roomsforms_keyword_section {
	color: @blue3;
}
.check-ok {
	color: @green4;
}

.form_traceability_base {
	background-color: #5bc0de;
}
.form_traceability_with_com {
	background-image: url(/images/fond-hachure-ok.png);
	animation-duration: 2s;
	animation-name: bganim;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
.scroll {
	height: 440px;
	overflow-y: auto;
	float: left;
	position: relative;
	margin-left: -5px;
}
.room_details_hiddentasks .M_ComboboxMultiItem.selected {
	background-color: #FE9885;
}
.room_details_hiddentasks .M_ComboboxMultiItem.notselected {
	background-color: @green3;
	color: white;
}

.m_room_block, m_form_block, m_person_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.m_room_name, .m_form_name, .m_person_name {
    text-align: center;
    margin-bottom: 5px;
    word-wrap: break-word;
    width: 100%;
    font-weight: bold;
  }

.score-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    opacity: 0.8;
}

.score-text {
    margin-right: 10px;
    font-weight: normal;
    color: #666;
    font-size: 0.9em;
}

.score-text-blue {
    color: #0477BF;
}

.score-progress-bar {
    flex-grow: 1;
    background-color: #dfdfdf;
    border-radius: 5px;
    overflow: hidden;
    height: 8px;
    position: relative;
}

.progress-fill {
    height: 100%;
    background-color: #0477BF;
    transition: width 0.3s ease;
}


.globalvision_tabs {
	display: flex;
	justify-content: start;
	gap: 20px;
}

.globalvision_tab {
	cursor: pointer;
	padding: 10px;
}

.globalvision_tabs_active {
	border-bottom: 3px solid #5380a7;
	color: #5380a7;
	padding-bottom: 2px;
}

.pt-10 {
	padding-top: 10px;
}



.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}


.popup.hidden {
    display: none;
}

.hidden {
	display: none;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: auto;
    max-width: 90%;
	max-height: 80vh;
    overflow-y: auto;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    word-wrap: break-word;
    word-break: break-word;
}


.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    cursor: pointer;
    color: #ff0000;
    font-weight: bold;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s;
}


.popup-text {
    margin-top: 20px;
    font-size: 14px;
    color: inherit;
    line-height: 1.5;
	white-space: pre-wrap;
	text-align: justify;
}

#notificationsBt {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.info-bubble {
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: none;
    transition: background-color 0.3s;
}

.info-bubble:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
.tooltip-container {
    position: relative;
    display: inline-block;
    text-align: center; 
}

.tooltip {
    display: none;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 15px;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 6px;
    font-size: 1.1em;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    white-space: nowrap;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    line-height: 1.4;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #f9f9f9 transparent transparent transparent;
}

.tooltip-color-box {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border-radius: 2px;
    vertical-align: middle;
}


.done-color {
    background-color: #A4C289;
}

.planning-color {
    background-color: #FE9885;
}

.undo-color {
    background-color: rgba(0, 0, 0, 0.2);
}

.tooltip-text {
    vertical-align: middle;
}
/* Styles pour le CONTENEUR de la liste des worksheets (grand rectangle) */
/* Style pour le CONTENEUR du menu déroulant */
.M_Col2.m_block_dropdown_container {
    margin-bottom: 25px; /* Marge en dessous */
    margin-right: 0;
    display: block; /* **AJOUT IMPORTANT : display: block; pour que le conteneur prenne toute la largeur disponible** */
    width: 100%; /* **AJOUT IMPORTANT : width: 100%; pour que le conteneur prenne toute la largeur de M_Col2** */
}

/* Style pour le menu déroulant <select> lui-même */
.m_block_dropdown {
    width: 100%; /* **CONFIRMATION : width: 100%; pour que le dropdown prenne toute la largeur du conteneur .m_block_dropdown_container** */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.1rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    padding-right: 30px;
}

.m_block_dropdown:focus {
    outline: none;
    border-color: #999;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
/* Style pour les titres de section (classe .m_block_keywords) - HAuteur MINIMALE CONSTANTE */

/* Ajuster la marge gauche de la liste des chambres (m_block_table) dans les sections */
.M_Col11 > .m_block_table { /* Cibler .m_block_table qui est ENFANT DIRECT (>) de .M_Col11 */
    margin-left: 25px; /* Ajuster la marge gauche ici - Exemple: 15px */
}