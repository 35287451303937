@import url( "variables.less" );

body,
html {
	font-family: @font-family-base;
	font-size: @font-size-base;
	line-height: @font-default-lineheight;

	height: 100%;
	margin: 0;
	padding: 0;

	color: @font-default-color;
}

p {
	margin-top: 0;
}

img {
	display: block;
}

h1,
.fc-toolbar h2 {
	font-size: 23px;
	font-weight: 300;
	line-height: 30px;

	margin: 0 0 10px 0;
}
h2 {
	font-size: 18px;
	font-weight: 300;
	line-height: 20px;

	margin: 18px 0 10px 0;
}
h1 + h2 {
	margin-top: 0;
}
h3 {
	font-size: 15px;
	font-weight: bold;
	line-height: 18px;

	margin: 15px 0 5px 0;
}
h3 + h1 {
	margin-top: -4px;
}
h4 {
	font-size: 13px;
	font-weight: bold;
	line-height: 16px;
}

h5 {
	font-size: 20px;
	font-weight: bold;
	line-height: 20px;

	margin: 0;
	padding: 0;
}

ul,
ul li {
	margin: 0;
	padding: 0;

	list-style: none;
}

a,
a:link,
a:visited {
	text-decoration: underline;

	color: @blue4;
}
a:hover {
	text-decoration: none;

	color: @blue4;
}

.red {
	color: @red3;
}
.green {
	color: @green3;
}

.M_TableAsLayout {
	width: 100%;
}
.M_TableAsLayout td {
	vertical-align: top;
	// margin-right: 15px;
}

hr {
	height: 1px;

	border: 0;
	border-bottom: 1px solid @gray1;
	// background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
	// background-image:      -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
}

* {
	box-sizing: border-box;
}
*:focus {
	outline: 0;
}

@M_HelpArrowSize: 8px;
@M_HelpBGColor: rgba(176, 190, 197, 0.9);
#M_Help {
	position: absolute;
	z-index: 100000001;
	// border: 1px solid #c2e1f5;
	// border-radius: 3px;

	display: none;

	max-width: 150px;
	padding: 10px;

	background-color: @M_HelpBGColor;
}
#M_Help.left:after {
	position: absolute;
	top: 15px;
	right: 100%;

	width: 0;
	height: 0;
	margin-top: -@M_HelpArrowSize;

	content: " ";
	pointer-events: none;

	border: solid transparent;
	border-width: @M_HelpArrowSize;
	border-color: rgba(136, 183, 213, 0);
	border-right-color: @M_HelpBGColor;
}
#M_Help.right:after {
	position: absolute;
	top: 15px;
	left: 100%;

	width: 0;
	height: 0;
	margin-top: -@M_HelpArrowSize;

	content: " ";
	pointer-events: none;

	border: solid transparent;
	border-width: @M_HelpArrowSize;
	border-color: rgba(136, 183, 213, 0);
	border-left-color: @M_HelpBGColor;
}

.M_allcontent {
	position: relative;

	overflow: hidden;

	width: 100%;
	height: 100%;
}
.M_Noselectable {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-touch-callout: none;
}

.M_ImgRound {
	border: 1px solid @gray1;
	border-radius: 50%;
	background-color: @monogray1;
	background-size: cover;
}
.M_ImgRoundContainer {
	padding: 3px;

	border: 2px solid @gray1;
	border-radius: 50%;
}

.M_FloatLeft,
.M_Float {
	float: left;
}
.M_FloatRight {
	float: right;
}
.M_float {
	float: left;
}
.M_floatright {
	float: right;
}

.M_Hidden {
	display: none;
}

.M_Content {
	// padding-left: (2 * @gutter);
	// padding-right: (2 * @gutter);
	height: 100%;
}
.M_Content.M_withToolbar {
	height: calc(100% - @toolbar-height);
}
.M_Content.M_withToolbar2 {
	height: calc(100% - @toolbar2-height);
}
.M_Row {
	// margin-right: (-1 * @gutter/2);
	// margin-left: (-1 * @gutter/2);
}
.M_Row:before {
	display: table;

	content: " ";
}
.M_Row:after {
	display: table;
	clear: both;

	content: " ";
}

.M_Pad {
	padding: @standard-padding;
}
.M_PadTop {
	padding-top: @standard-padding;
}
.M_PadLeft {
	padding-left: @standard-padding;
}
.M_PadBottom {
	padding-bottom: @standard-padding;
}
.M_PadRight {
	padding-right: @standard-padding;
}
.M_Pad2 {
	padding: (2 * @standard-padding);
}
.M_Margin {
	margin: @gutter;
}
.M_MarginRight {
	margin-right: @gutter;
}
.M_MarginLeft {
	margin-left: @gutter;
}
.M_MarginLeftRight {
	// margin-right: (@gutter / 2);
	// margin-left: (@gutter / 2);
}
// .M_Row .M_MarginLeftRight:first-child {
//     // margin-left: 0;
// }
// .M_Row .M_MarginLeftRight:last-child {
//     // margin-right: 0;
// }
.M_MarginBottom {
	margin-bottom: @gutter;
}
.M_MarginTop {
	margin-top: @gutter;
}
.M_Padding {
	padding: @gutter;
}
.M_PaddingRight {
	padding-right: @gutter;
}
.M_PaddingLeft {
	padding-right: @gutter;
}
.M_PaddingBottom {
	padding-bottom: @gutter;
}
.M_PaddingTop {
	padding-top: @gutter;
}
.M_Border {
	border: 1px solid @border-color;
}
.M_BorderRight {
	border-right: 1px solid @gray1;
}
.M_BorderLeft {
	border-left: 1px solid @gray1;
}
.M_BorderBottom {
	border-bottom: 1px solid @gray1;
}
.M_BorderTop {
	border-top: 1px solid @gray1;
}
.M_Gutter {
	width: @gutter;
}

.M_Flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: row;

	-webkit-box-direction: normal;
	-webkit-box-orient: horizontal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
}
.M_Flex > div {
	// background-color: green;
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1 0;
	flex: 1 1 0;
}
.M_Flex.M_FlexGutter {
	margin-right: (-1 * @gutter / 2);
	margin-left: (-1 * @gutter / 2);
	// padding-right: (@gutter / 2);
	// padding-left: (@gutter / 2);
}
.M_Flex.M_FlexGutter > div {
	margin-right: (@gutter / 2);
	margin-left: (@gutter / 2);
}
.M_FlexW10 {
	width: 10%;
}
.M_FlexW20 {
	width: 20%;
}
.M_FlexW25 {
	width: 25%;
}
.M_FlexW33 {
	width: 33.333333333333%;
}
.M_FlexW50 {
	width: 50%;
}

.generate-M_FlexGrow(@nbCols);
.generate-M_FlexGrow(@n, @i: 1) when (@i =< @n) {
	@temp: (@i * 100% / @n);
	.M_Flex > div.M_FlexGrow@{i} {
		// position: relative;
		// width: calc(@temp - (@gutter / 2));
		-webkit-box-flex: @i;
		-webkit-flex-grow: @i;
		flex-grow: @i;
		-ms-flex-positive: @i;
	}
	.generate-M_FlexGrow(@n, (@i + 1));
}

.generate-M_Col(@nbCols);
.generate-M_Col(@n, @i: 1) when (@i =< @n) {
	@temp: (@i * 100% / @n);
	.M_Col@{i} {
		position: relative;

		float: left;
		// padding-right: (@gutter / 2);
		// padding-left: (@gutter / 2);

		box-sizing: border-box;
		width: @temp;
	}
	.M_Col@{i}.M_MarginRight {
		width: calc(@temp - @gutter);
	}
	.M_Col@{i}.M_MarginLeft {
		width: calc(@temp - @gutter);
	}
	.M_Col@{i}.M_MarginLeftRight {
		width: calc(@temp - @gutter);
	}
	// .M_Col@{i}:first-child {
	// 	margin-left: (@gutter / 2);
	// }
	// .M_Col@{i}:last-child {
	// 	margin-right: 0;
	// }
	// .M_Col@{i}.M_MarginLeftRight:first-child {
	// 	width: calc(@temp - @gutter / 2);
	// }
	// .M_Col@{i}.M_MarginLeftRight:last-child {
	// 	width: calc(@temp - @gutter / 2);
	// }

	.M_TableAsLayout td.M_Col@{i} {
		float: none;

		width: @temp;
	}
	.M_TableAsLayout td.M_Col@{i}:last-child {
		float: none;

		width: auto;
	}
	.M_TableAsLayout td.M_Col@{i}.M_MarginRight {
		float: none;

		width: @temp;
	}
	.M_TableAsLayout td.M_Col@{i}.M_MarginLeft {
		float: none;

		width: @temp;
	}
	.generate-M_Col(@n, (@i + 1));
}

.M_LabelLeft {
	float: left;

	width: 30%;
	padding-top: 3px;
}
input[disabled].M_Input,
textarea[disabled].M_Input {
	color: white;
	background-color: @gray2;
}

.M_InputLeft {
	float: left;

	width: 70%;
}
.M_LabelRight {
	float: left;

	width: auto;
	padding-top: 0;
	padding-left: 10px;
}
.M_InputRight {
	float: left;

	width: auto;
}
input[type="checkbox"].M_InputLeft {
	width: auto;
	margin-top: 6px;
	margin-right: @standard-padding;
}
// .M_Row:after, .M_FormGroup:after {
// 	clear: both;
// 	display: table;
// 	content: ""
// }

.M_Clear {
	clear: both;
}
.M_clear {
	clear: both;
}

.M_paneltable {
	// max-height: 100%;
}
.M_paneltable .panel-body {
	overflow: hidden;

	padding: 0;
}
.M_part {
	// background-color: @gray2;
	position: absolute;
	top: 0;
	left: 0;

	overflow: hidden;

	width: 100%;
	height: 100%;
	padding: @gutter;
}
.M_editable:hover {
	outline: none;
	background-color: @gray2;
	// box-sizing: border-box;
	// display: inline-block;
	// border: 1px dotted transparent;
}
.M_editing {
	outline: none;
	background-color: @gray3;
}

.M_editablePlaceholder {
	font-style: italic;

	cursor: text;

	color: @gray5;
}

.M_italic {
	font-style: italic;

	color: @gray2;

	font-size: 11px;
}

.M_editable:focus {
	// border: 1px dotted @gray-darker;
	background-color: @gray2;
}
.M_editablePlaceholder::after {
	content: attr(data-m-after);
}
// *[contenteditable] {
// 	outline: none;
// }
// *[contenteditable]:hover, *[contenteditable].edit {
// 	background-color: @gray6;
// }
// *[contenteditable]:focus {
// 	background-color: @gray5;
// }
// *[contenteditable].edit.placeholder {
// 	color: @gray4;
// }

.M_Table {
	width: 100%;
	margin: 0;
	padding: 0;
}
.M_Table td,
.M_Table th {
	margin: 0;
	padding: 0;
}
.M_margintop {
	margin-top: (@standard-padding);
}
.M_paneltable .panel-bodyreal {
	overflow: auto;

	height: 100%;
}
.M_showonedit {
	display: none;
}

.M_pagination2 > div {
	float: left;
}
.M_pagination2 > div > input {
	font-size: 12px;

	display: block;

	width: 25px;
	height: 25px;
	margin: 0;
	padding: 0;

	text-align: center;

	border: 1px solid @border-color;
	border-left: 0;
}
.M_pagination2 > div.fa-chevron-left,
.M_pagination2 > div.fa-chevron-right {
	width: 25px;
	height: 25px;
	padding-top: 5px;

	cursor: pointer;
	text-align: center;

	border: 1px solid @border-color;
}
.M_pagination2 > div.fa-chevron-left {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.M_pagination2 > div.fa-chevron-right {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.M_pagination2_ofpages {
	font-size: 13px;

	padding: 5px 10px 0 5px;
}
.M_FormNumber {
	text-align: right;
}
.M_ComboboxMultiItem {
	float: left;

	margin: 0 5px 5px 0;
	padding: 2px 5px;

	cursor: pointer;

	background-color: @gray1;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.M_ComboboxMultiItem .fa-trash {
	cursor: pointer;
}
.M_ComboboxMultiItem.selected {
	color: white;
	background-color: @green3;
}
.M_ComboboxMultiItem.selected1 {
	color: white;
	background-color: @green3;
}
.M_ComboboxMultiItem.selected2 {
	color: white;
	background-color: @orange1;
}
.M_ComboboxMultiItem.selected3 {
	color: white;
	background-color: @red3;
}

.M_MultiCombobox input {
	float: left;

	width: 100px;
	margin: 0;
	padding: 0;

	border: 0;
	outline: none;
}
.M_MultiCombobox .form-control {
	width: 100%;
	height: auto;
	// padding: (@padding-base-vertical - 2) 12px 0 12px;
	// min-height: @input-height-base;
}
.M_MultiCombobox .input-group-btn {
	vertical-align: top;
}
.M_MultiCombobox_Item {
	float: left;

	margin: 0 5px 3px 0;
	padding: 0 5px;

	color: white;
	border-radius: 5px;
	background-color: #428bca;
}
.M_MultiCombobox_Item .fa-trash {
	cursor: pointer;
}

.M_AlignRight {
	text-align: right;
}
.M_AlignCenter {
	text-align: center;
}
.M_AlignLeft {
	text-align: left;
}
.M_Pointer {
	cursor: pointer;
}
.M_Absolute {
	position: absolute;
}

.M_Badge {
	// position: absolute;
	font-size: 12px;

	padding: 2px 5px;

	color: white;
	border-radius: 10px;
	background-color: @red5;
}

.M_Button > span.badge {
  background-color: red;
  color: white;
  width: 20px; /* Size of the badge */
  height: 20px; /* Size of the badge */
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 1; /* Ensures the text is centered */
  margin-left: 10px;
}

div.fitContent {
	min-width: fit-content;
}

.M_Button.withBadge {
	display: flex;
	align-items: center;
	justify-content: center;
}

.M_SimpleList {
	position: relative;

	overflow: auto;

	height: 100%;
	min-height: 60px;
}
.M_SimpleListContent {
	background-image: url(/images/bg_simplelist.png);
}
.M_SimpleListItem:first-child {
	border-top: 1px solid @gray1;
}
.M_SimpleListItem {
	overflow: hidden;

	box-sizing: border-box;
	height: @cell-height;
	padding: ((@cell-height - @font-size-base)/2 - 1) @standard-padding 0 @standard-padding;

	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	text-overflow: ellipsis;

	border-bottom: 1px solid @gray1;
	outline: none;
}

@tableListHeaderHeigth: 30px;
.M_TableList {
	// height: 100%;
	position: relative;

	padding-top: @tableListHeaderHeigth;
}
.M_TableList > div:first-child {
	overflow: auto;

	height: 100%;

	border-top: 1px solid @gray1;
}
.M_TableList table {
	width: 100%;

	table-layout: fixed;
	border-collapse: collapse;
	// height: 100%;
}
.M_TableList thead {
	height: 0;
}
.M_TableList thead th,
.M_TableList thead td,
.M_TableList tbody td {
	font-size: 12px;

	height: @cell-height;
	padding: 0 @standard-padding;

	cursor: pointer;

	border-bottom: 1px solid @gray1;
}
.M_TableList thead th {
	padding-top: 0;
	padding-bottom: 0;

	border: none;
	//	white-space: nowrap;
}
.M_TableList.M_FullHeight thead th {
	line-height: 0;

	height: 0;

	color: transparent;
}
.M_TableList thead td {
	font-weight: bold;
}
.M_TableList thead th > div {
	font-size: 12px;
	font-weight: bold;
	line-height: @font-default-lineheight;

	position: absolute;
	top: 0;

	height: @tableListHeaderHeigth;
	margin-left: (-1*@standard-padding);
	padding: 5px @standard-padding 0 @standard-padding;

	text-transform: uppercase;

	color: @font-default-color;
}
.M_TableList .M_TableListOdd,
.M_SimpleList .M_TableListOdd {
	-webkit-transition: background-color 0.5s ease 0s;
	transition: background-color 0.5s ease 0s;

	background-color: @monogray1;
}
.M_TableList .M_TableListEven,
.M_SimpleList .M_TableListEven {
	-webkit-transition: background-color 0.5s ease 0s;
	transition: background-color 0.5s ease 0s;

	background-color: transparent;
}

.M_SimpleListItem.selected {
	background-color: @blue2;
}
.M_SimpleListItem.over {
	background-color: @green4;
}

.M_TableList.M_TableListOverRaw tbody tr:hover,
.M_TableList.M_TableListOverRaw tbody tr.M_TableListEven:hover,
.M_TableList.M_TableListOverRaw tbody tr.M_TableListOdd:hover,
.M_SimpleListItem:hover,
.M_SimpleListItem.M_TableListEven:hover,
.M_SimpleListItem.M_TableListOdd:hover {
	background-color: @blue1;
}

.M_SimpleListItem.readed {
	background-color: @green3;
}
.M_SimpleListItem.selected.readed {
	background-color: @green2;
}

// .M_TableList tbody tr:hover {

// }

.M_FullHeight,
.M_PanelFullHeight,
.M_WindowContent,
.M_Tabs {
	position: relative;

	height: 100%;
}
.M_PanelScroller,
.M_Tab {
	position: relative;

	overflow: auto;

	height: 100%;
}

.M_Scroller {
	overflow: auto;
}
.M_Tab {
	display: none;
}
.M_Tab.active {
	display: block;
}

.M_TabButton {
	float: left;

	margin: 0 5px 0 0;
	padding: 10px 15px;

	cursor: pointer;

	border: 1px solid @gray1;
	border-bottom: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-color: @gray1;
}
.M_TabButton.active {
	background-color: white;
}

.M_Panel {
	// padding: @standard-padding;
	background-color: white;
}
// venditore

ul.M_BulletStandard {
	padding: auto;

	list-style: disc;
}
ul.M_BulletStandard li {
	margin: 0 0 0 40px;

	list-style: disc;
}

.M_ModalCancel {
	margin-right: (@standard-padding / 2);
}
.M_Button {
	font-family: @font-family-sans-serif;
	font-size: @font-size-base;

	padding: 10px 20px;

	cursor: pointer;
	-webkit-transition: background-color 0.3s ease 0s;
	transition: background-color 0.3s ease 0s;

	color: white;
	border: 0;
	border-radius: 3px;
	outline: none;
	background-color: @gray3;
	// box-shadow: inset 3px 3px 11px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}
.M_Button:hover {
	background-color: @gray2;
}
.M_Button.primary {
	background-color: @blue3;
}
.M_Button.primary:hover {
	background-color: @blue2;
}
.M_Button.danger {
	background-color: @red3;
}
.M_Button.danger:hover {
	background-color: @red2;
}
.M_Button.inform {
	background-color: @green4;
}
.M_Button.inform:hover {
	background-color: @green3;
}
.M_Button.light {
	background-color: @yellow2;
}
.M_Button.light:hover {
	background-color: @yellow3;
}
.M_Button.bold {
	font-weight: bold;
}
.M_Button[disabled],
.M_Button:disabled,
.M_Button:hover[disabled] {
	cursor: not-allowed;

	color: @monogray4;
	border: 1px solid @monogray4;
	// background-image: url(/images/fond-barres2.png) ;
	background-color: transparent;
}
.M_Button.little {
	font-size: 12px;

	padding: 5px 15px;
}
.M_button.medium {
	padding: 4px 10px;
}

.M_Bullet li {
	margin-left: 40px;

	list-style: disc;
}
.M_Drawer {
	position: absolute;

	box-sizing: border-box;
	width: 45px;
	height: 45px;
	padding: 5px @standard-padding;

	border-top: 1px solid @gray1;
	background-color: white;
}

.M_Modal {
	position: absolute;
	// -webkit-transition: opacity 0.5s;
	//         transition: opacity 0.5s;
	z-index: 500;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;
	background-color: black;
}

.M_Window {
	position: absolute;
	// border-radius: 10px;
	z-index: 10;
	top: 0;
	left: -9999px;

	overflow: hidden;
	// right: 0;
	// bottom: 0;

	width: 600px;
	height: 200px;
	padding: (@standard-padding * 2) (@standard-padding * 3);
	// margin: 100px auto;

	background-color: white;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
}
.M_WindowHeader {
	height: 50px;
}
.M_WindowHeaderWithDisclaimer {
	height: 100px;
}
.M_WindowFooter {
	height: 50px;
	padding: @standard-padding 0 0 0;
}
.M_WindowBody {
	overflow: auto;

	height: calc(100% - 100px);
}
.M_WindowBodyWithDisclaimer {
	overflow: auto;

	height: calc(100% - 150px);
}
.M_IconBig {
	font-size: 50px;

	color: @red3;
}
.M_Dropdown {
	position: absolute;

	display: none;
	overflow: auto;

	height: auto;
	margin-bottom: 10px;
	padding: 10px 5px;

	border-radius: 5px;
	// width: 200px;
	// height: 100px;
	background-color: white;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
}
.M_DropdownMenu {
	padding: 5px 10px;

	cursor: pointer;
}
.M_DropdownMenu:hover {
	background-color: @gray1;
}
.M_DropdownMenu.disabled {
	color: @gray1;
}
.M_DropdownMenu.disabled:hover {
	cursor: Not-Allowed;

	background-color: transparent;
}
.M_DropdownSeparation {
	margin: 5px 10px;

	border-bottom: 1px solid @gray1;
}

.M_CalendarMonth {
	width: 260px;
}
.M_CalendarMonth .notthegoodmonth,
.bigcalendar .M_CalendarMonth_content tbody tr td.notthegoodmonth:nth-child(6),
.bigcalendar .M_CalendarMonth_content tbody tr td.notthegoodmonth:nth-child(7),
.M_CalendarMonth .notselectable {
	border-radius: 0;
	background-color: @monogray2;
}
.M_CalendarMonth .current {
	color: white;
	background-color: @yellow1;
}
.M_CalendarMonth_content tbody tr.alllineover {
	background-color: @monogray2;
}
.M_CalendarMonth_days {
	// padding: 10px;
}
.M_CalendarMonth_months {
	// padding: 10px;
}
.M_CalendarMonth_years {
	// padding: 10px;
}
.M_CalendarMonth * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.M_CalendarMonth_content table {
	width: 100%;

	border-collapse: collapse;
}
.M_CalendarMonth_previous {
	float: left;

	width: 10%;

	cursor: pointer;
	text-align: center;
}
.M_CalendarMonth_previous:hover {
	background-color: #eeeeee;
}
.M_CalendarMonth_date {
	float: left;

	width: 80%;

	cursor: pointer;
	text-align: center;
}
.M_CalendarMonth_date:hover {
	background-color: @monogray1;
}
.M_CalendarMonth_next {
	float: right;

	width: 10%;

	cursor: pointer;
	text-align: center;
}
.M_CalendarMonth_next:hover {
	background-color: #eeeeee;
}
.M_CalendarMonth_content {
	clear: both;
	// overflow: hidden;
}
.M_CalendarMonth_content th {
	font-weight: bold;

	text-align: center;
}
.M_CalendarMonth_content td {
	width: 30px;
	height: 30px;
	//  border-radius: 50%;

	cursor: pointer;
	text-align: center;
}
.M_CalendarMonth_content td:hover {
	background-color: #eeeeee;
}
.M_CalendarMonth_content td.active {
	color: white;
	background-color: @green1;
}
.M_CalendarMonth_content td.selected1 {
	background-color: #5bc0de;
	color: white;
}
.M_CalendarMonth_content td.selected2 {
	background-color: #ffd86a;
	color: white;
}
.M_CalendarMonth_content td.selected3 {
	background-color: @red6;
	color: white;
}

.M_CalendarMonth_years .M_CalendarMonth_content td {
	padding: 0 10px;
}
.M_CalendarMonth_years .M_CalendarMonth_date:hover {
	background-color: transparent;
}
// .M_CalendarMonth .M_CalendarMonth_content td:hover {
// 	background-color: transparent;
// }
.M_CalendarMonth .M_CalendarMonth_txt {
	padding: 0;
}
.M_CalendarMonth.M_CalendarMonthView {
	width: auto;
}
.M_CalendarMonth.M_CalendarMonthView .M_CalendarMonth_txt {
	padding: 2px 0 0 5px;
}

.M_CalendarMonth.M_CalendarMonthView .M_CalendarMonth_content td {
	position: relative;

	width: 14.286%;
	padding: 0;

	text-align: left;
	vertical-align: top;

	border: 1px solid @gray1;
	// min-height: 90px;
}
.M_CalendarMonthView_bar {
	font-size: 10px;
	line-height: 10px;

	position: absolute;
	z-index: 1;

	overflow: hidden;

	height: 14px;
	padding: 1px 5px 0 2px;

	color: white;
	background-color: @green3;
}
.M_CalendarMonthView_bars {
	position: relative;
}

.M_Toolbar {
	overflow: hidden;

	box-sizing: border-box;
	height: @toolbar-height;
	padding: 10px @standard-padding;

	border-bottom: 1px solid @blue1;
	background-color: white;
}
.M_Toolbar2 {
	// color: white;
	font-weight: normal;

	overflow: hidden;
	// background-color: @gray0;

	box-sizing: border-box;
	height: @toolbar2-height;
	padding: @standard-padding @standard-padding 0 @standard-padding;
}
.M_Toolbar2 .fa {
	// font-size: 20px;
}
.M_Toolbar2 h1,
.M_Toolbar2 h2 {
	margin: 0;
}
.M_Toolbar .M_Button {
	padding: 5px 20px;
}
.M_Toolbar.bottom {
	// border-top: 1px solid #03a9f4;
	border-bottom: 0 solid #03a9f4;
}

.M_FormRateItem {
	float: left;

	margin: 0 5px 0 0;
	padding: 3px;

	cursor: pointer;

	background-color: red;
}
.M_FormRateItem.over {
	background-color: yellow;
}

.M_RadioGroupItem.col:after {
	display: table;
	clear: both;

	content: " ";
}
.M_RadioGroupItem.inline {
	float: left;

	margin-right: 15px;
}
.M_RadioGroupItem label {
	font-weight: normal;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="number"],
label,
textarea {
	font-family: @font-family-base;
	font-size: (@font-size-base + 0);

	width: 100%;

	color: @font-default-color;
	border-radius: 5px;
	&.M_Error {
		border-color: #843534;
		background-color: @red3bis;
		// box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
	}
	&.M_Error2 {
		border-color: #43a04b;
		background-color: #79e282;
		// box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #43a04b;
	}
}
input.M_Combobox {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
input.M_Combobox_Left {
	border-left: none !important;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
label {
	font-size: (@font-size-base - 3);
	font-weight: bold;

	display: block;

	text-transform: uppercase;

	color: @gray2;
}
input[type="text"],
input[type="search"],
input[type="password"],
input[type="number"],
textarea {
	height: 30px;
	margin: 0;
	padding: 3px 10px;

	border-collapse: collapse;

	border: 1px solid @gray1;
}
textarea {
	overflow-x: hidden;

	box-sizing: border-box;
	min-height: 25px;
}
.M_FormGroup {
	margin-bottom: @little-padding;
}
.M_FormGroup .M_FormGroup {
	margin-bottom: 0;
}
.M_FormGroup .M_FormGroup:after {
	display: none;
	clear: none;

	content: "";
}
.M_FormGroup .fa {
	cursor: pointer;
}
.M_FormGroup:after {
	display: table;
	clear: both;

	content: " ";
}
.M_FormInputGroup {
	display: table;

	width: 100%;

	border-spacing: 0;
	border-collapse: separate;
}
.M_FormCaret {
	display: table-cell;

	width: 30px;

	cursor: pointer;
	text-align: center;

	border: 1px solid @gray1;
	border-left: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: white;
}

.M_FormCaret_Left {
	display: table-cell;

	width: 30px;

	cursor: pointer;
	text-align: center;

	border: 1px solid @gray1;
	border-right: 0;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	background-color: white;
}

.M_FormButton {
	display: table-cell;

	width: 30px;

	cursor: pointer;
	text-align: center;

	border: 1px solid @gray1;
	border-radius: 5px;
	background-color: white;
}

.M_FormInputGroup input {
	display: table-cell;

	width: 100%;
}

.M_IconFa {
	font-size: 14px;

	cursor: pointer;

	color: white;
}

.M_Relative {
	position: relative;

	height: 0;
}

.M_Editor button {
	font-size: 15px;

	margin-right: -1px;
	padding: 6px 12px;

	cursor: pointer;

	border: 1px solid @gray1;
	outline: none;
	background-color: white;
	// -webkit-transition: all 0.3s ease 0s;
	//         transition: all 0.3s ease 0s;
}
.M_Editor button.active {
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.M_FormEditor-Content {
	overflow: auto;

	margin-top: 2px;
	padding-right: 30px;

	border: 1px solid #cccccc;
	border-radius: 4px;
	background-color: white;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.M_FormEditor-Content:focus {
	outline: none;
}
.M_FormEditor-Path {
	font-size: 10px;

	text-transform: lowercase;
}

.isEditing table td {
	border: 1px dotted black;
}

.m_dropherenewimage {
	width: 100%;
	height: 200px;
	margin-top: 3px;
	padding-top: 80px;

	text-align: center;

	border: 1px solid #333333;
	border-radius: 8px;
}
.m_dropherenewdocument {
	width: 100%;
	height: 200px;
	margin-top: 3px;
	padding-top: 80px;

	text-align: center;

	border: 1px solid #333333;
	border-radius: 8px;
}

.m_dropherenewdocument_edited {
	width: 100%;
	margin-top: 3px;
	text-align: center;
	border: 1px solid #333333;
	border-radius: 8px;
	padding: 10px;
}

.m_fileoutput img {
	float: left;

	margin: 0 15px 15px 0;
}

.M_Tree ul {
	margin: 0 0 0 25px;
	padding: 0;
}
.M_Tree li {
	position: relative;

	margin: 0;
	padding: 10px 5px 0 5px;

	list-style-type: none;
}
.M_Tree li::before,
.M_Tree li::after {
	position: absolute;
	right: auto;
	left: -20px;

	content: "";
}
.M_Tree li::before {
	top: 0;
	bottom: 50px;

	width: 1px;
	height: 100%;

	border-left: 1px solid #999999;
}
.M_Tree li::after {
	top: 25px;

	width: 25px;
	height: 20px;

	border-top: 1px solid #999999;
}
.M_Tree li span {
	display: inline-block;

	padding: 3px 8px;

	cursor: pointer;
	text-decoration: none;

	border: 1px solid #999999;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.M_Tree li.M_TreeParentLi > span {
	cursor: pointer;
}
.M_Tree > ul > li::before,
.M_Tree > ul > li::after {
	border: 0;
}
.M_Tree li:last-child::before {
	height: 25px;
}

@SliderHeight: 30px;
@SliderCursorSize: 24px;
.M_SliderBar {
	// width: (@SliderHeight * 2);
	position: relative;

	float: left;

	height: @SliderHeight;

	cursor: pointer;

	border-radius: (@SliderHeight / 2);
	background-color: @green2;
}
.M_SliderCursor {
	position: absolute;
	top: ((@SliderHeight - @SliderCursorSize) / 2);
	left: ((@SliderHeight - @SliderCursorSize) / 2);

	width: @SliderCursorSize;
	height: @SliderCursorSize;

	border-radius: (@SliderCursorSize / 2);
	background-color: white;
}
.M_SliderLabelLeft,
.M_SliderLabelRight {
	float: left;

	width: 100px;
	padding: 5px;
}
.M_SliderLabelLeft {
	text-align: right;
}

.M_Important {
	display: inline-block;

	color: white;
	background-color: @red3;
}
.M_DivPassword {
	position: absolute;

	padding: 3px 5px 0 5px;

	color: white;
	background-color: @red2;
}
.M_Resetform {
	font-size: 30px;
	cursor: pointer;
}

input[type="text"].M_FormHour_hour,
input[type="text"].M_FormHour_minute {
	width: 50px;
	text-align: center;
}
.M_FormHour_hour_up,
.M_FormHour_hour_down,
.M_FormHour_minute_up,
.M_FormHour_minute_down {
	text-align: center;
}
#prevButton,
#nextButton{
 	width: 35px;
 	height: 25px;
 }

 .switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
  }

  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }

  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }

  .slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }

  input:checked + .slider {
	background-color: #2196F3;
  }

  input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }

  .slider.round:before {
	border-radius: 50%;
  }

  .M_tab {
	padding:8px 100px 8px 16px!important;
	border-bottom:6px solid #ccc!important;
	color:#626C750!important;
	width: fit-content !important;
  }

  .M_tab:hover {
	background-color:white!important
  }

.M_tab-selected {
	background-color: white;
	border-color:#14a7bd!important
}

.M_tab_empty {
	border-bottom:6px solid #ccc!important;
	flex-grow: 1;
}

a.M_tab-link {
	color: #626C75;
  }

  .M_tab-container {
	display: flex;
    flex-direction: row;
  }

  .popoverChild {
	display: block;
	position: absolute;
	background-color: #f9f9f9;
	border: 1px solid #ddd;
	padding: 10px;
	z-index: 1;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
  }

  /* Position the popover below the button */
  .popoverParent + .popover {
	margin-top: 5px;
  }

  .M_Toggle {
	display: flex;
  }

  .M_Toggle > label.M_LabelLeft {
	margin: auto;
	font-size: 12px;
  }

  .M_Input.M_BigSize {
	height: 40px !important;
  }
